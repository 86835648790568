import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';

import social from './social.json';

library.add(far, fab);

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <span>
          © All rights are reserved | {new Date().getFullYear()} |{' '}
          <span aria-label="love" role="img">
            ❤
          </span>{' '}
        </span>
      </Details>
      <Links>
        {social.map(({ id, name, link, iconprefix, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <FontAwesomeIcon icon={[`${iconprefix}`, `${icon}`]} />
          </a>
        ))}
      </Links>
    </Flex>
  </Wrapper>
);
